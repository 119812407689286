<template>
  <div>
    <ejs-dialog
      ref="lotteryAggregatesPopup"
      header="일자별 추첨예약 목록"
      :width="w"
      :height="h"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :enableResize="true"
      :close="closePopup"
    >
      <div class="window lotteryReservationStatusByDate">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">추첨일자</div>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                          v-model="searchDateInputDateRange"
                          type="lookupDetail-condition"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul class="lookup-button">
                  <li class="lookup keyColor">
                    <erp-button
                        button-div="GET"
                        :is-shortcut-button="true"
                        @click.native="fetch"
                    >
                      조회
                    </erp-button>
                  </li>
                </ul>
                <ul class="lookup-condition">
                  <li class="field">
                    <ul class="content">
                      <li class="item check">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="radio"
                                value="DATE"
                                v-model="gridSearchDiv"
                                name="gridSearchDiv"
                                @change="onGridSearchDivChange"
                              />
                              <i></i>
                              <div class="label">날짜별</div>
                            </label>
                          </li>
                          <li>
                            <label>
                              <input
                                type="radio"
                                value="TIME"
                                v-model="gridSearchDiv"
                                name="gridSearchDiv"
                                @change="onGridSearchDivChange"
                              />
                              <i></i>
                              <div class="label">시간대별</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="lookup-right">
                <ul class="lookup-button">
                  <li v-if="loginEmail === 'hanjm87@fixelsoft.com'">
                    <erp-button
                      button-div="SAVE"
                      @click.native="tgResveLotteryCmpetrtInsertByAggregatesButtonClicked"
                    >
                      경쟁률 집계 테스트
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section
                  class="article-section section-01"
                  v-show="this.gridSearchDiv === 'DATE'"
                >
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="LotteryAggregatesGridByDate"
                      :provides="provides"
                      :columns="columnsByDate"
                      :dataSource="dataSource"
                      :editSettings="editSettings"
                      :aggregates="aggregates"
                      :allowExcelExport="true"
                      @headerCellInfo="headerCellInfo"
                      @queryCellInfo="queryCellInfo"
                      @actionComplete="actionComplete"
                    />
                  </div>
                </section>
                <section
                  class="article-section section-01"
                  v-show="this.gridSearchDiv === 'TIME'"
                >
                  <div class="section-caption">
                    <div class="caption-navigation">
                      <ejs-tab
                        :showCloseButton="false"
                        heightAdjustMode="Auto"
                        :selected="onTabSelected"
                        overflowMode="Popup"
                      >
                        <e-tabitems>
                          <e-tabitem :header="{ text: '희망시간 1' }"></e-tabitem>
                          <e-tabitem :header="{ text: '희망시간 2' }"></e-tabitem>
                        </e-tabitems>
                      </ejs-tab>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="LotteryAggregatesGridByTime"
                      :provides="provides"
                      :columns="columnsByTime"
                      :dataSource="dataSource"
                      :editSettings="editSettings"
                      :allowExcelExport="true"
                      @headerCellInfo="headerCellInfo"
                      @queryCellInfo="queryCellInfoByTime"
                      @actionComplete="actionComplete"
                    />
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section
                  id="chart-area"
                  class="article-section section-02"
                >
                  <line-chart
                    :options="options"
                    :styles="{height: '220px'}"
                    :chart-data="chartDataSource"
                  />
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li>
              <erp-button
                  button-div="FILE"
                  @click.native="excel"
              >
                Excel · Chart
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closePopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .article-section.section-01 {height: calc(100% - 220px)}
body .article-section.section-02 {height: 220px}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import moment from 'moment';
import LineChart from '@/utils/charts/LineChart.js';
import {Resize, ForeignKey, Aggregate, Group, ExcelExport} from '@syncfusion/ej2-vue-grids';
import inputDateRange from '@/components/common/datetime/InputDateRange';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import commonMixin from '@/views/layout/mixin/commonMixin';
import {numberWithCommas} from "@/utils/number";
import {
  commonCodesGetCommonCode,
  commonCodesGetColorValue, commonCodesGetComName,
} from '@/utils/commonCodes';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import {toPng} from 'html-to-image';
import {getDateRange} from "@/utils/date";
import {orderBy as _orderBy} from "lodash";
import {getLoginEmail} from "@/utils/session/SessionUtil";

export default {
  name: 'LotteryAggregatesPopup',
  components: {
    LineChart,
    inputDateRange,
    ejsGridWrapper,
    ErpButton
  },
  mixins: [commonMixin],
  data() {
    return {
      dataSource: [],
      chartData: [],
      fromDate: null,
      toDate: null,
      gridSearchDiv: "DATE",
      selectedTabIndex: 0,
      provides: [Resize, ForeignKey, Aggregate, Group, ExcelExport],
      columnsByDate: [
        {
          field: 'lotteryDate',
          headerText: '날짜',
          isPrimaryKey: true,
          type: 'string',
          minWidth: 16,
          width: 90,
          textAlign: 'center',
        },
        {
          field: 'dwCode',
          headerText: '요일',
          type: 'string',
          minWidth: 16,
          width: 60,
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'DW_CODE',
        },
        {
          field: 'timeCount',
          headerText: '티타임',
          type: 'number',
          format: 'N',
          minWidth: 16,
          width: 70,
          textAlign: 'Center',
        },
        {
          field: 'lotteryRceptCount',
          headerText: '신청자 수',
          type: 'number',
          format: 'N',
          minWidth: 16,
          width: 60,
          textAlign: 'Center',
        },
        {
          field: 'applicationForTime',
          headerText: '티타임 대비 신청',
          type: 'string',
          minWidth: 16,
          width: 80,
          textAlign: 'Center',
        },
        {
          field: 'competitionRate',
          headerText: '경쟁률',
          type: 'string',
          minWidth: 16,
          width: 70,
          textAlign: 'Center',
        },
      ],
      columnsByTime: [
        {
          field: 'lotteryDate',
          headerText: '추첨일자',
          isPrimaryKey: true,
          type: 'string',
          minWidth: 16,
          width: 70,
          textAlign: 'center',
        },
        {
          field: 'dwCode',
          headerText: '요일',
          type: 'string',
          minWidth: 16,
          width: 60,
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'DW_CODE',
        },
        {
          field: "hopeTimeZone",
          headerText: '시간대',
          type: 'string',
          minWidth: 16,
          width: 60,
          textAlign: 'center',
        },
        {
          field: 'timeCount',
          headerText: '티타임 수',
          type: 'number',
          format: 'N',
          minWidth: 16,
          width: 70,
          textAlign: 'Center',
        },
        {
          headerText: "전체 신청 현황",
          columns: [
            {
              field: 'lotteryRceptCount',
              headerText: '신청자 수',
              type: 'number',
              format: 'N',
              minWidth: 16,
              width: 70,
              textAlign: 'Center',
            },
            {
              field: 'competitionRate',
              headerText: '경쟁률',
              type: 'string',
              minWidth: 16,
              width: 70,
              textAlign: 'Center',
            },
          ],
        },
        {
          headerText: "유효 신청 현황",
          columns: [
            {
              field: 'validApplicantCount',
              headerText: '신청자 수',
              type: 'number',
              format: 'N',
              minWidth: 16,
              width: 70,
              textAlign: 'Center',
            },
            {
              field: 'competitionRateByValidApplicantCount',
              headerText: '경쟁률',
              type: 'string',
              minWidth: 16,
              width: 70,
              textAlign: 'Center',
            },
          ],
        },
        {
          field: 'resveOutCount',
          headerText: '미당첨',
          type: 'number',
          format: 'N',
          minWidth: 16,
          width: 70,
          textAlign: 'Center',
        },
        {
          field: 'ineligibleCount',
          headerText: '비고 (부적격)',
          type: 'number',
          format: 'N',
          minWidth: 16,
          width: 90,
          textAlign: 'Center',
        },
      ],
      editSettings: {
        mode: 'Batch',
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
        showConfirmDialog: false,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        hover: {animationDuration: 0},
        animation: {
          duration: 1,
          onComplete: function() {
            const chartInstance = this.chart
                , ctx = chartInstance.ctx
                , legendItems = chartInstance.legend.legendItems;
            ctx.fillStyle = '#333';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            this.data.datasets.forEach(function (dataset, i) {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                const data = dataset.data[index];
                if (data > 0 && !legendItems[i].hidden) {
                  ctx.fillText(numberWithCommas(data), bar._model.x, bar._model.y - 5);
                }
              });
            });
          }
        },
      },
      isFullscreen: false,
    };
  },
  async mounted() {
    await this.$nextTick();
    await this.fetch();
    const $el = document.getElementsByClassName('.e-dlg-header-content');
    const $copyEl = $el.firstElementChild.cloneNode(true);
    $copyEl.id = 'custom-fullscreen';
    $copyEl.classList.add('fullscreen');
    $copyEl.setAttribute('title', 'Fullscreen');
    $copyEl.setAttribute('aria-title', 'Fullscreen');
    $copyEl.addEventListener('click', () => this.onToggleScreenSizeClick());
    $copyEl.removeEventListener('click', () => this.onToggleScreenSizeClick());
    $el.insertBefore($copyEl, $el.lastChild);
  },
  computed: {
    loginEmail() {
      return getLoginEmail();
    },
    w() {
      return this.isFullscreen ? 'calc(100% - 20px)' : 1000;
    },
    h() {
      return this.isFullscreen ? '100%' : 760;
    },
    chartDataSource() {
      const comCodes = commonCodesGetCommonCode('LOTTERY_RCEPT_STATUS').filter(item => item.comCode !== "RCEPT_CANCEL");
      const datasets = comCodes?.map(comCode => ({
        data: this.chartData?.map(item => item[comCode.comCode]),
        label: comCode.comName,
        borderWidth: 1,
        borderColor: commonCodesGetColorValue('LOTTERY_RCEPT_STATUS', comCode.comCode),
        backgroundColor: commonCodesGetColorValue('LOTTERY_RCEPT_STATUS', comCode.comCode),
        tension: 0,
        fill: false,
      }));
      return {
        labels: this.chartData?.map(item => `${commonCodesGetColorValue('DW_CODE', item.dwCode) === '#000000' ? '' : '● '}${moment(item.lotteryDate).format('MM-DD')}`),
        datasets
      };
    },
    searchDateInputDateRange: {
      get: function() {
        return {
          from: this.fromDate,
          to: this.toDate,
        };
      },
      set: function(dateRange) {
        this.fromDate = dateRange.from;
        this.toDate = dateRange.to;
      },
    },
    aggregates() {
      return [
        {
          columns: [
            {
              field: "dwCode",
              aggregationType: "TotalCaption",
              customAggregate: "평균",
            },
            {
              field: "timeCount",
              aggregationType: "TotalCaption",
              customAggregate: "주중",
            },
            {
              field: "lotteryRceptCount",
              aggregationType: "TotalCaption",
              customAggregate: this.byDateGridFootTotalWeekdayRceptCountAggregateFn,
            },
            {
              field: "applicationForTime",
              aggregationType: "TotalCaption",
              customAggregate: this.byDateGridFootTotalWeekdayApplicationForTimeAggregateFn,
            },
            {
              field: "competitionRate",
              aggregationType: "TotalCaption",
              customAggregate: this.byDateGridFootTotalWeekdayCompetitionRateAggregateFn,
            },
          ]
        },
        {
          columns: [
            {
              field: "dwCode",
              aggregationType: "TotalCaption",
              customAggregate: "평균",
            },
            {
              field: "timeCount",
              aggregationType: "TotalCaption",
              customAggregate: "주말",
            },
            {
              field: "lotteryRceptCount",
              aggregationType: "TotalCaption",
              customAggregate: this.byDateGridFootTotalWeekendRceptCountAggregateFn,
            },
            {
              field: "applicationForTime",
              aggregationType: "TotalCaption",
              customAggregate: this.byDateGridFootTotalWeekendApplicationForTimeAggregateFn,
            },
            {
              field: "competitionRate",
              aggregationType: "TotalCaption",
              customAggregate: this.byDateGridFootTotalWeekendCompetitionRateAggregateFn,
            },
          ]
        },
      ];
    },
  },
  methods: {
    async showPopup(fromDate, toDate = fromDate) {
      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    excel() {
      if (this.gridSearchDiv === "TIME") {
        this.$refs.LotteryAggregatesGridByTime.excelExport();
      } else {
        this.$refs.LotteryAggregatesGridByDate.excelExport();
      }
      const node = document.getElementById('chart-area');
      toPng(node, {backgroundColor: '#FFF'})
        .then(dataUrl => {
          const link = document.createElement('a');
          link.download = `chart_${this.fromDate}_${this.toDate}.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch(err => {
          alert(err);
        });
    },
    closePopup() {
      this.$emit('popupClosed');
    },
    async onToggleScreenSizeClick() {
      this.isFullscreen = !this.isFullscreen;
      await this.$nextTick();
      const $el = document.getElementById('custom-fullscreen');
      $el.classList.remove(!this.isFullscreen ? 'fullscreen' : 'notfullscreen');
      $el.classList.add(this.isFullscreen ? 'fullscreen' : 'notfullscreen');
    },
    headerCellInfo(args) {
      const {
        cell: {column: {headerText}},
        node,
      } = args;
      if (headerText === 'NO') {
        node.classList.add(this.$t('className.grid.noSortFilter'));
      }
    },
    queryCellInfo(args) {
      const {
        column: {field, type},
        cell,
        data,
      } = args;
      if (field === 'dwCode') {
        cell.style.color = commonCodesGetColorValue(
          'DW_CODE',
          data.dwCode,
        );
      }
      if (field === 'competitionRate') {
        cell.style.backgroundColor = '#EDF6FA';
      }
      if (type === 'number' && data[field] < 1) {
        cell.innerText = '-';
      }
    },
    queryCellInfoByTime(args) {
      const {
        column: {field, type},
        cell,
        data,
      } = args;
      if (field === 'dwCode') {
        cell.style.color = commonCodesGetColorValue(
          'DW_CODE',
          data.dwCode,
        );
      }
      if (
        field === 'competitionRate' ||
        field === "competitionRateByValidApplicantCount"
      ) {
        cell.style.backgroundColor = '#EDF6FA';
      }
      if (type === 'number' && data[field] < 1) {
        cell.innerText = '-';
      }

      if (field === "lotteryDate" || field === "dwCode") {
        if (data.isRowSpanFlag) {
          args.rowSpan = data.rowSpanCount;
        }
      }
    },
    actionComplete() {
      // if (args.requestType === 'refresh') {
      //   if (this.gridSearchDiv === "TIME") {
      //     if (
      //       this.$refs.LotteryAggregatesGridTime.getSelectedRecords().length === 0 &&
      //       this.dataSource.length > 0
      //     ) {
      //       this.$refs.LotteryAggregatesGridTime.selectRow(0);
      //     }
      //   } else {
      //     if (
      //       this.$refs.LotteryAggregatesGridDate.getSelectedRecords().length === 0 &&
      //       this.dataSource.length > 0
      //     ) {
      //       this.$refs.LotteryAggregatesGridDate.selectRow(0);
      //     }
      //   }
      // }
    },
    async fetch() {
      const data = await GolfErpAPI.fetchReservationLotteryAggregates(this.fromDate, this.toDate, null);

      let result = [];

      if (this.gridSearchDiv === "DATE") {
        const dateRangeArr = getDateRange(this.fromDate, this.toDate);

        dateRangeArr.forEach(item => {
          const bsnCode = data?.gridData?.filter(gridItem => gridItem.lotteryDate === item).length > 0 ? data?.gridData?.filter(gridItem => gridItem.lotteryDate === item)[0].bsnCode : null;
          const dwCode = data?.gridData?.filter(gridItem => gridItem.lotteryDate === item).length > 0 ? data?.gridData?.filter(gridItem => gridItem.lotteryDate === item)[0].dwCode : null;
          const timeCnt =
            data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).length > 0 ?
              data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).map(mapItem => mapItem.timeCnt).reduce((acc, cur) => acc + cur) :
              0
          ;
          const rcepterCnt =
            data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).length > 0 ?
              data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).map(mapItem => mapItem.rcepterCnt).reduce((acc, cur) => acc + cur) :
              0
          ;
          const validTimeCnt =
            data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).length > 0 ?
              data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).map(mapItem => mapItem.validTimeCnt).reduce((acc, cur) => acc + cur) :
              0
          ;
          const validRcepterCnt =
            data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).length > 0 ?
              data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).map(mapItem => mapItem.validRcepterCnt).reduce((acc, cur) => acc + cur) :
              0
          ;
          const przwnerCnt =
            data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).length > 0 ?
              data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).map(mapItem => mapItem.przwnerCnt).reduce((acc, cur) => acc + cur) :
              0
          ;
          const unPrzwnerCnt =
            data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).length > 0 ?
              data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).map(mapItem => mapItem.unPrzwnerCnt).reduce((acc, cur) => acc + cur) :
              0
          ;
          const inadqcCnt =
            data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).length > 0 ?
              data?.gridData?.filter(gridItem => gridItem.lotteryDate === item && gridItem.hopeOrder === 1).map(mapItem => mapItem.inadqcCnt).reduce((acc, cur) => acc + cur) :
              0
          ;

          const rceptPercent = (timeCnt > 0 ? (Math.floor((rcepterCnt / timeCnt) * 1000) / 10).toFixed(1).toString() : "0") + "%";
          const validRceptPercent = (validTimeCnt > 0 ? (Math.floor((validRcepterCnt / validTimeCnt) * 1000) / 10).toFixed(1).toString() : "0") + "%";
          const rceptRate = (timeCnt > 0 ? (Math.floor((rcepterCnt / timeCnt) * 100) / 100).toString() : "0") + ":1";
          const validRceptRate = (validTimeCnt > 0 ? (Math.floor((validRcepterCnt / validTimeCnt) * 100) / 100).toString() : "0") + ":1";

          result.push({
            lotteryDate: item,
            bsnCode,
            dwCode,
            timeCount: timeCnt, // 타임수
            lotteryRceptCount: rcepterCnt, // 접수자수
            validTimeCnt, // 유효타임수
            validRcepterCnt, // 유효접수자수
            przwnerCnt, // 당첨자수
            unPrzwnerCnt, // 미당첨자수
            inadqcCnt, // 부적격자수
            applicationForTime: rceptPercent, // 티타임 대비 신청
            validRceptPercent, // 티타임 대비 신청(유효)
            competitionRate: rceptRate, // 경쟁률
            validRceptRate, // 경쟁률(유효)
          });
        });
      } else {
        let lotterDate = null;
        const lotteryDateByCount = [];
        let bsnDate = null;
        // eslint-disable-next-line no-unused-vars
        let bsnDateByCnt = 0;
        data.gridData.filter(item => item.hopeOrder === (this.selectedTabIndex === 1 ? 2 : 1))
        .forEach(item => {
          if (bsnDate === null || bsnDate === item.lotteryDate) {
            bsnDateByCnt += 1;
          } else {
            bsnDateByCnt = 1;
          }
          bsnDate = item.lotteryDate;

          const findIndex = lotteryDateByCount.findIndex(findData => findData.lotteryDate === item.lotteryDate);
          if (findIndex < 0) {
            lotteryDateByCount.push({
              lotteryDate: bsnDate,
              count: bsnDateByCnt,
            });
          } else {
            lotteryDateByCount[findIndex] = {
              lotteryDate: bsnDate,
              count: bsnDateByCnt,
            };
          }
        });

        result = data.gridData.filter(item => item.hopeOrder === (this.selectedTabIndex === 1 ? 2 : 1)).map(item => {
          lotterDate = item.lotteryDate;
          return {
            ...item,
            hopeTimeZone: Number(item.hopeTimeZone) ? Number(item.hopeTimeZone) : commonCodesGetComName("HOPE_TIME_ZONE", item.hopeTimeZone),
            timeCount: item.timeCnt,
            lotteryRceptCount: item.rcepterCnt,
            validApplicantCount: item.validRcepterCnt,
            resveOutCount: item.unPrzwnerCnt,
            ineligibleCount: item.inadqcCnt,
            competitionRate: (item.timeCnt > 0 ? (Math.floor((item.rcepterCnt / item.timeCnt) * 100) / 100).toString() : "0") + ":1",
            competitionRateByValidApplicantCount: (item.timeCnt > 0 ? (Math.floor((item.validRcepterCnt / item.timeCnt) * 100) / 100).toString() : "0") + ":1",
            isRowSpanFlag: item.lotteryDate === lotterDate,
            rowSpanCount: lotteryDateByCount.find(a => a.lotteryDate === item.lotteryDate)?.count || 0
          };
        });
        console.log('result.===>', result);
      }

      this.dataSource = _orderBy(result, ["lotteryDate", "hopeTimeZone"]);

      const set = new Set(data?.chartData?.map(i => i.lotteryDate));
      const uniqueArr = [...set];
      let chartResult = [];
      uniqueArr?.forEach(lotteryDate => {
        const theDayData = data?.chartData
          ?.filter(i => i.lotteryDate === lotteryDate && i.lotteryRceptStatus !== "RCEPT_CANCEL");
        const restCount = theDayData.length > 0 && theDayData[0].restCount;
        const remainingTeamCount = theDayData.length > 0 && theDayData[0].remainingTeamCount;
        const block = {};
        const comCodes = commonCodesGetCommonCode('LOTTERY_RCEPT_STATUS');
        comCodes?.forEach(comCode => {
          block[comCode.comCode] = theDayData
            ?.filter(i => i.lotteryRceptStatus === comCode.comCode)
            ?.reduce((acc, cur) => acc + cur.count, 0);
        });
        block.dwCode = theDayData.length > 0 && theDayData[0].dwCode;
        block.tot = theDayData?.reduce((acc, cur) => acc + cur.count, 0);
        block.restCount = restCount;
        block.remainingTeamCount = remainingTeamCount;
        const compete = Math.floor((block.tot / restCount) * 100) / 100;
        block.compete = restCount > 0 && compete > 0 ? `${compete % 1 === 0 ? parseInt(compete) : compete}:1` : '-';
        chartResult.push({
          lotteryDate,
          ...block,
        });
      });

      this.chartData = chartResult;
    },
    byDateGridFootTotalWeekdayRceptCountAggregateFn() {
      return (this.dataSource.map(data => data.bsnCode === "WEEKDAY" ? data.lotteryRceptCount : 0).reduce((acc, cur) => acc + cur)).toString() + "명";
    },
    byDateGridFootTotalWeekdayApplicationForTimeAggregateFn() {
      const sumRceptCount = this.dataSource.map(data => data.bsnCode === "WEEKDAY" ? data.lotteryRceptCount : 0).reduce((acc, cur) => acc + cur);
      const sumTimeCount = this.dataSource.map(data => data.bsnCode === "WEEKDAY" ? data.timeCount : 0).reduce((acc, cur) => acc + cur);

      return (sumTimeCount > 0 ? (Math.floor((sumRceptCount / sumTimeCount) * 1000) / 10).toFixed(1).toString() : "0") + "%";
    },
    byDateGridFootTotalWeekdayCompetitionRateAggregateFn() {
      const sumRceptCount = this.dataSource.map(data => data.bsnCode === "WEEKDAY" ? data.lotteryRceptCount : 0).reduce((acc, cur) => acc + cur);
      const sumTimeCount = this.dataSource.map(data => data.bsnCode === "WEEKDAY" ? data.timeCount : 0).reduce((acc, cur) => acc + cur);

      return (sumTimeCount > 0 ? (Math.floor((sumRceptCount / sumTimeCount) * 100) / 100).toString() : "0") + ":1";
    },
    byDateGridFootTotalWeekendRceptCountAggregateFn() {
      return (this.dataSource.map(data => data.bsnCode === "WEEKEND" ? data.lotteryRceptCount : 0).reduce((acc, cur) => acc + cur)).toString() + "명";
    },
    byDateGridFootTotalWeekendApplicationForTimeAggregateFn() {
      const sumRceptCount = this.dataSource.map(data => data.bsnCode === "WEEKEND" ? data.lotteryRceptCount : 0).reduce((acc, cur) => acc + cur);
      const sumTimeCount = this.dataSource.map(data => data.bsnCode === "WEEKEND" ? data.timeCount : 0).reduce((acc, cur) => acc + cur);

      return (sumTimeCount > 0 ? (Math.floor((sumRceptCount / sumTimeCount) * 1000) / 10).toFixed(1).toString() : "0") + "%";
    },
    byDateGridFootTotalWeekendCompetitionRateAggregateFn() {
      const sumRceptCount = this.dataSource.map(data => data.bsnCode === "WEEKEND" ? data.lotteryRceptCount : 0).reduce((acc, cur) => acc + cur);
      const sumTimeCount = this.dataSource.map(data => data.bsnCode === "WEEKEND" ? data.timeCount : 0).reduce((acc, cur) => acc + cur);

      return (sumTimeCount > 0 ? (Math.floor((sumRceptCount / sumTimeCount) * 100) / 100).toString() : "0") + ":1";
    },
    onGridSearchDivChange() {
      this.fetch();
    },
    onTabSelected(args) {
      const { selectedIndex } = args;

      this.selectedTabIndex = selectedIndex;

      this.fetch();
    },
    async tgResveLotteryCmpetrtInsertByAggregatesButtonClicked() {
      await GolfErpAPI.tgResveLotteryCmpetrtInsertByAggregates(this.fromDate, this.toDate);
    }
  },
};
</script>
