<template>
  <div>
    <ejs-dialog
      ref="lotteryReservationConfirmPopup"
      header="예약확정 확인"
      width="300"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      @close="onLotteryReservationConfirmPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-text">
                      예약 확정 작업을 진행하시겠습니까?<br>예약 확정시 되돌릴 수 없습니다
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li style="margin-top: 6px; margin-right: 9px;">
              <label>
                <input
                  :class="[
                    'f-check',
                    isSmsSend ? 'active' : undefined,
                  ]"
                  style="float: left;"
                  type="checkbox"
                  v-model="isSmsSend"
                >
                <div style="float: left; user-select: none;">
                  SMS 발송
                </div>
              </label>
            </li>
            <li class="apply keyColor">
              <erp-button
                  button-div="SAVE"
                  is-icon-custom="true"
                  @click.native="applyButtonClicked"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .window .windowContent .body-article .section-body {overflow: visible; border: none;}
body .window .windowContent .body-article .section-body .body-text {margin: -3px 0 -4px 0;}

body .f-check {width: 13px;height: 13px;-webkit-appearance: none;-moz-appearance: none;border-color: #398a6a;background-color: #398a6a;cursor: pointer;}
body .f-check:not(.active) {border: 1px solid #e0e0e0;background-color: #fff;}
body .f-check.active:before {display: block;width: 7px;height: 7px;margin: 3px;background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAA/CAYAAAAse9ITAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA25pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo1YmQyNTVjNC1kODhlLWJiNDItOTI4MC03NGM1YjllNGM5NmMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NzZDQTUyRTFDNTg4MTFFOThBMjU4RTkxRDYyODBDMEUiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NzZDQTUyRTBDNTg4MTFFOThBMjU4RTkxRDYyODBDMEUiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Qjg5NEYzMkJDNTg0MTFFOThENTc5MUY1MDFDNzIwMUYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Qjg5NEYzMkNDNTg0MTFFOThENTc5MUY1MDFDNzIwMUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7ftzCXAAAAaUlEQVR42mL8//8/Ay7AxIAb/Mcl+R+m8z82CSBgZEITgEvAdDJik0B2ECO6BLprGdFdxXLQwmK4eEVC//dw8QqX3ffh4hWfC/rDxSvMf6KHi1c0z/4cLl7R//d/uHjlrvbuoeMVgAADAJBhTr8wER99AAAAAElFTkSuQmCC) no-repeat center 0;content: '';}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "LotteryReservationConfirmPopup",
  components: {ErpButton},
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas
  },
  data() {
    return {
      isSmsSend: true,
      isSave: false,
      searchOptions: null,
    };
  },
  created() {},
  destroyed() {},
  computed: {},
  methods: {
    showPopup(args) {
      console.log('args.===>', args);

      this.searchOptions = args.searchOptions;
    },
    onLotteryReservationConfirmPopupClosed() {
      this.$emit("popupClosed", this.isSave);
    },
    async applyButtonClicked() {
      await GolfErpAPI.reservationLotteryProvisionalAssignmentReservationConfirm(({
        ...this.searchOptions,
        isSmsSend: this.isSmsSend,
      }));

      this.infoToast("예약 확정이 완료되었습니다");

      this.isSave = true;

      this.closeButtonClicked();
    },
    closeButtonClicked() {
      this.$refs.lotteryReservationConfirmPopup.hide();
    },
  }
};
</script>
